import React from "react";
import CustomDialogBox from "../../shared/CustomDialogBox/CustomDialogBox";
import { fontSize } from "../../../constants/styled";
import { getEventSummaryData } from "../../../utils/apiCalls";
import moment from 'moment';
import CustomButton from "../../shared/CustomButton/CustomButton";
import SummarySkeleton from "./Skeleton/SummarySkeleton";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../../store/notificationSlices";
const SummaryDialog = ({ showDialog, eventId, eventStatus, authToken, setShowDialog = () => { } }) => {
    const title = {
        Pending: "See Pending Action Status",
        Ongoing: "See Closed Alert",
        Closed: "See Status of Ongoing Surveillance",
        Completed: "See Completed Outbreak Details",
    }
    const [summaryData, setSummaryData] = React.useState({ isFetching: true, data: null, reload: false })
    const [eventTimelineExpandFlag, setEventTimelineExpandFlag] = React.useState(true);
    const [outbreakReportExpandFlag, setOutbreakReportExpandFlag] = React.useState(true);
    const [preliminaryreportExpandFlag, setPreliminaryreportExpandFlag] = React.useState(false);
    const dispatch = useDispatch()
    let rendered = true

    const showActionErrorNotification = (message) => {
        dispatch(notificationActions.resetNotification());
        setTimeout(() => {
            dispatch(notificationActions.setNoification({
                open: true,
                message
            }));
        }, 100);
    }

    const handleApiCall = async (type) => {
        const summary = await getEventSummaryData(eventId, authToken)
        if (type === "main") {
            setSummaryData((prev) => ({ ...prev, isFetching: false, data: summary }))
        } else if (type === "reload") {
            if (summaryData?.data?.outbreak_status !== summary?.outbreak_status) {
                setSummaryData((prev) => ({ ...prev, reload: false, data: summary }))
            } else {
                setSummaryData((prev) => ({ ...prev, reload: false }))
            }
        }
        if (!summary) {
            showActionErrorNotification(`Failed to get summary data for event id ${eventId}`)
        }
    }
    const handleReload = async () => {
        setSummaryData((prev) => ({ ...prev, reload: true }))
        handleApiCall("reload")
    }
    React.useEffect(() => {
        if (rendered) {
            setSummaryData((prev) => ({ ...prev, isFetching: true }))
            handleApiCall("main")
            rendered = false
        }
        return () => {
            setSummaryData({ isFetching: true, data: null, reload: false })
        }
    }, [])
    return <>
        <CustomDialogBox
            showDialog={showDialog}
            classes="w-9/12"
            clickOutsideClose={true}
            header={{
                text: title[eventStatus],
                classes: `bg-white text-[#413F40] font-[600] ${fontSize[24]} p-2 rounded-4`,
            }}
            setShowDialog={setShowDialog}
        >
            {summaryData.isFetching && <SummarySkeleton />}
            {!summaryData.isFetching && <div className="pl-2 py-2 md:py-3 lg:pl-4 xl:pl-5 pr-4 md:pr-5 lg:pr-6 xl:pr-7 flex flex-col relative top-0">
                <div className="flex">
                    <div className="w-[4rem] flex flex-col items-center">
                        <div className={`flex justify-center items-center bg-blue rounded-[50%] h-[2rem] w-[2rem] p-3 text-white`}><p>1</p></div>
                        <div className={`w-[2px] h-full bg-blue`}></div>
                    </div>
                    <div className="w-full bg-[#f3faff] py-3 rounded-[6px] shadow-lg mb-2 relative top-[-12px]">
                        <div className="flex justify-between px-3 font-[600]">
                            <div className={`${fontSize[16]}`}>Event Timeline</div>
                            <div className="text-[#2b87b5] ">
                                <CustomButton
                                    fontSize={fontSize[12]}
                                    text={{ text: "See Details", class: "" }}
                                    icon={{ post: true, class: `${eventTimelineExpandFlag ? "pi-angle-up" : "pi-angle-down"} text-[8px] bg-[#2b87b5] rounded-[50%] p-[2px] text-white font-[500]` }}
                                    background="bg-[#d4eaf0] px-3 py-1"
                                    border="rounded-[12px]"
                                    hover="hover:cursor-pointer"
                                    action={() => setEventTimelineExpandFlag(!eventTimelineExpandFlag)}
                                />
                            </div>
                        </div>
                        {eventTimelineExpandFlag && <div className={`mt-6 space-y-4 font-[600] text-black w-full`}>
                            <div className="pl-3 flex justify-between text-[#979797] font-[500] pr-6">
                                <p className={`${fontSize[14]}`}>Action</p>
                                <p className={`${fontSize[14]} w-1/6 text-center`}>Date</p>
                            </div>
                            <hr className="text-gray-light"/>
                            <div className="pl-3 flex justify-between pr-6">
                                <p className={`${fontSize[14]}`}>Extracted Date</p>
                                <p className={`${fontSize[12]} w-1/6 text-center`}>{moment(summaryData?.data?.event_timeline?.extracted_date).format('DD MMM YYYY')}</p>
                            </div>
                            <hr className="text-gray-light"/>
                            <div className="pl-3 flex justify-between pr-6">
                                <p className={`${fontSize[14]}`}>Shortlisted Date</p>
                                <p className={`${fontSize[12]} w-1/6 text-center`}>{moment(summaryData?.data?.event_timeline?.shortlist_date).format('DD MMM YYYY')}</p>
                            </div>
                            <hr className="text-gray-light"/>
                            <div className="pl-3 flex justify-between pr-6">
                                <p className={`${fontSize[14]}`}>Published Date</p>
                                <p className={`${fontSize[12]} w-1/6 text-center`}>{moment(summaryData?.data?.event_timeline?.published_date).format('DD MMM YYYY')}</p>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="flex">
                    <div className="w-[4rem] flex flex-col items-center">
                        <div className={`flex justify-center items-center ${summaryData?.data?.investigation_required ? "bg-blue" : "bg-gray"} text-white rounded-[50%] h-[2rem] w-[2rem] p-3`}><p>2</p></div>
                        {(summaryData?.data?.outbreak_status === "Ongoing" || summaryData?.data?.outbreak_status === "Completed") && <div className={`w-[2px] h-full bg-blue`}></div>}
                    </div>
                    <div className={`w-full flex justify-between bg-[#f3faff] p-3 ${summaryData?.data?.investigation_required ? "pr-6" : "pr-4"} rounded-[6px] shadow-lg mb-2 ${fontSize[16]} font-[600] relative top-[-12px]`}>
                        <div className="flex items-center space-x-4">
                            <p>Investigation Required</p>
                            {!summaryData?.data?.investigation_required && <div>
                                <i className={`pi pi-sync ${summaryData.reload ? "pi-spin text-blue" : ""} hover:cursor-pointer`} style={{ fontSize: '1rem' }} onClick={handleReload}></i>
                            </div>}
                        </div>
                        <p className={`${summaryData?.data?.investigation_required ? "text-blue" : "text-gray"}`}>{summaryData?.data?.investigation_required || "No response yet"}</p>
                    </div>
                </div>
                {(summaryData?.data?.outbreak_status === "Ongoing" || summaryData?.data?.outbreak_status === "Completed") && <div className="flex">
                    <div className="w-[4rem] flex flex-col items-center">
                        <div className={`flex justify-center items-center bg-blue rounded-[50%] h-[2rem] w-[2rem] p-3 text-white`}><p>3</p></div>
                    </div>
                    <div className="w-full bg-[#f3faff] py-3 rounded-[6px] shadow-lg mb-2 relative top-[-12px]">
                        <div className="flex justify-between px-3 font-[600]">
                            <div className={`${fontSize[16]} flex items-center`}>
                                <p>Outbreak Report</p>
                                {summaryData?.data?.outbreak_status === "Ongoing" && <div className="flex items-center pl-2">
                                    <p>(Ongoing)</p>
                                    <div>
                                        <i className={`pi pi-sync ${summaryData.reload ? "pi-spin text-blue" : ""} hover:cursor-pointer pl-3`} style={{ fontSize: '1rem' }} onClick={handleReload}></i>
                                    </div>
                                </div>}
                            </div>
                            <div className="text-[#2b87b5] ">
                                <CustomButton
                                    fontSize={fontSize[12]}
                                    text={{ text: "See Details", class: "" }}
                                    icon={{ post: true, class: `${outbreakReportExpandFlag ? "pi-angle-up" : "pi-angle-down"} text-[8px] bg-[#2b87b5] rounded-[50%] p-[2px] text-white font-[500]` }}
                                    background="bg-[#d4eaf0] px-3 py-1"
                                    border="rounded-[12px]"
                                    hover="hover:cursor-pointer"
                                    action={() => setOutbreakReportExpandFlag(!outbreakReportExpandFlag)}
                                />
                            </div>
                        </div>
                        {outbreakReportExpandFlag && <div className={`mt-3 space-y-4 font-[600] text-black`}>
                            <hr className="text-[#85ced0]"/>
                            <div>
                                <div className="flex justify-between px-3 font-[600]">
                                    <div className={`${fontSize[14]} font-[700]`}>Preliminary Investigation Report</div>
                                    <div className="text-[#2b87b5] ">
                                        <CustomButton
                                            fontSize={fontSize[12]}
                                            text={{ text: "See Details", class: "" }}
                                            icon={{ post: true, class: `${preliminaryreportExpandFlag ? "pi-angle-up" : "pi-angle-down"} text-[8px] bg-[#2b87b5] rounded-[50%] p-[2px] text-white font-[500]` }}
                                            background="bg-[#d4eaf0] px-3 py-1"
                                            border="rounded-[12px]"
                                            hover="hover:cursor-pointer"
                                            action={() => setPreliminaryreportExpandFlag(!preliminaryreportExpandFlag)}
                                        />
                                    </div>
                                </div>
                                {preliminaryreportExpandFlag && <div className={`${fontSize[14]} px-3 pt-2 text-blue font-[700]`}>{summaryData?.data?.preliminary_investigation}</div>}
                            </div>
                            <hr className="text-[#85ced0]"/>
                            <div className="pl-3 w-full flex justify-between">
                                <div className="w-1/4">
                                    <p className={`${fontSize[14]} font-[700]`}>Outbreak Reported Date</p>
                                    <p className={`${fontSize[14]} font-[700] text-blue`}>{summaryData?.data?.outbreak_reported_date}</p>
                                </div>
                                <div className="w-1/4">
                                    <p className={`${fontSize[14]} font-[700]`}>Outbreak Start Date</p>
                                    <p className={`${fontSize[14]} font-[700] text-blue`}>{summaryData?.data?.outbreak_start_date}</p>
                                </div>
                                <div className="w-1/4">
                                    <p className={`${fontSize[14]} font-[700]`}>Number of Cases/Deaths</p>
                                    <p className={`${fontSize[14]} font-[700] text-blue`}>{summaryData?.data?.cases}/{summaryData?.data?.deaths}</p>
                                </div>
                            </div>
                            <hr className="text-[#85ced0]"/>
                            <div className="pl-3 w-full flex justify-between">
                                <div className="w-1/4">
                                    <p className={`${fontSize[14]} font-[700]`}>Final Health condition Name</p>
                                    <p className={`${fontSize[14]} font-[700] text-blue`}>{summaryData?.data?.final_health_condition_name}</p>
                                </div>
                                <div className="w-1/4">
                                    <p className={`${fontSize[14]} font-[700]`}>SSO Verified Status</p>
                                    <p className={`${fontSize[14]} font-[700] text-blue`}>{summaryData?.data?.sso_verified_status}</p>
                                </div>
                                <div className="w-1/4">
                                    <p className={`${fontSize[14]} font-[700]`}>SSO Verified Date</p>
                                    <p className={`${fontSize[14]} font-[700] text-blue`}>{summaryData?.data?.sso_verified_date || "-"}</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>}
            </div>}
        </CustomDialogBox>
    </>
}

export default SummaryDialog;
